import React, { useEffect, useRef, useContext, useReducer } from "react";
import { useHistory, useParams } from "react-router-dom";
import { parseISO, format, isSameDay } from "date-fns";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import Badge from "@material-ui/core/Badge";
import IconButton from "@material-ui/core/IconButton";
import { i18n } from "../../translate/i18n";
import DoneIcon from "@material-ui/icons/Done";
import SyncAltIcon from "@material-ui/icons/SyncAlt";

import ReplayIcon from "@material-ui/icons/Replay";
import LockIcon from "@material-ui/icons/Lock";
import api from "../../services/api";
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";
import MarkdownWrapper from "../MarkdownWrapper";
import { Tooltip } from "@material-ui/core";
import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";
import { TicketListItensTagsContainer } from "../TicketListItensTagsContainer";

const useStyles = makeStyles((theme) => ({
  ticket: {
    position: "relative",
  },

  pendingTicket: {
    cursor: "unset",
  },

  noTicketsDiv: {
    display: "flex",
    height: "100px",
    margin: 40,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  noTicketsText: {
    textAlign: "center",
    color: "rgb(104, 121, 146)",
    fontSize: "14px",
    lineHeight: "1.4",
  },

  noTicketsTitle: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "600",
    margin: "0px",
  },

  contactNameWrapperLine1: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "-5px",
  },

  contactNameWrapperLine2: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "-7px",
    marginBottom: "-14px",
  },

  contactNameWrapperLine3: {
    display: "flex",
    justifyContent: "start",
    marginTop: "1px",
    marginBottom: "-7px",
  },

  closedBadge: {
    alignSelf: "center",
    justifySelf: "flex-end",
    marginRight: 32,
    marginLeft: "auto",
  },

  iconOptionsLine1: {
    padding: 2,
    top: "-2px",
  },

  iconOptionsLine2: {
    padding: 2,
    top: "-5px",
  },

  badgeStyle: {
    position: "absolute",
    top: "-16px",
    left: "-17px",
    color: "white",
    backgroundColor: green[500],
  },

  contactLastMessage: {
    paddingRight: 40,
    maxWidth: "50em",
    boxSizing: "border-box",
  },

  acceptButton: {
    position: "absolute",
    left: "50%",
  },

  ticketQueueColor: {
    flex: "none",
    width: "8px",
    height: "100%",
    position: "absolute",
    top: "0%",
    left: "0%",
  },

  lastMessageTime: {
    justifySelf: "flex-end",
  },

  userTag: {
    maxHeight: "1.43em",
    minWidth: "8em",
    textAlign: "center",
    right: 5,
    bottom: 10,
    background: "#2F76D2",
    color: "#ffffff",
    borderRadius: 10,
    fontSize: "0.8em",
  },

  userConnection: {
    color: "#ffffff",
    fontSize: "0.8em",
    marginTop: "7px",
  },

  connection: {
    marginRight: "6px",
    borderRadius: 8,
    padding: "0 10px",
    border: "0.1px solid #AAAAAD",
    textShadow: "1px 1px 2px #000",
    backgroundColor: "green",
  },
}));

const reducer = (state, action) => {
  if (action.type === "LOAD_USERS") {
    const users = action.payload;
    const newUsers = [];

    users.forEach((user) => {
      const userIndex = state.findIndex((u) => u.id === user.id);
      if (userIndex !== -1) {
        state[userIndex] = user;
      } else {
        newUsers.push(user);
      }
    });

    return [...state, ...newUsers];
  }
};

const TicketListItem = ({ ticket }) => {
  const classes = useStyles();
  const history = useHistory();
  const { ticketId } = useParams();
  const isMounted = useRef(true);
  const { user } = useContext(AuthContext);
  const [users, dispatch] = useReducer(reducer, []);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleUpdateTicketStatus = async (e, status, userId) => {
    try {
      await api.put(`/tickets/${ticket.id}`, {
        status: status,
        userId: userId || null,
      });
      if (status === "open") {
        history.push(`/tickets/${ticket.id}`);
      } else {
        history.push("/tickets");
      }
    } catch (err) {
      toastError(err);
    }
  };

  const handleAcepptTicket = async (id, start) => {
    try {
      await api.put(`/tickets/${id}`, {
        status: "open",
        userId: user?.id,
        startedAt: start === null ? Date() : start,
        finishedAt: null,
      });
    } catch (err) {
      toastError(err);
    }
    history.push(`/tickets/${id}`);
  };

  const handleClosedTicket = async (id, start) => {
    try {
      await api.put(`/tickets/${id}`, {
        status: "closed",
        userId: user?.id,
        startedAt: start === null ? Date() : start,
        finishedAt: Date(),
      });
    } catch (err) {
      toastError(err);
    }
  };

  const handleSelectTicket = (id) => {
    history.push(`/tickets/${id}`);
  };

  return (
    <React.Fragment key={ticket.id}>
      <ListItem
        dense
        button
        onClick={(e) => {
          handleSelectTicket(ticket.id);
        }}
        selected={ticketId && +ticketId === ticket.id}
        className={clsx(classes.ticket, {
          [classes.pendingTicket]: ticket.status === "pending",
        })}
      >
        <Tooltip
          arrow
          placement="right"
          title={ticket.queue?.name || "Sem setor"}
        >
          <span
            style={{ backgroundColor: ticket.queue?.color || "#F1F1F1" }}
            className={classes.ticketQueueColor}
          ></span>
        </Tooltip>
        <Badge
          badgeContent={ticket.unreadMessages}
          classes={{
            badge: classes.badgeStyle,
          }}
          overlap="rectangular"
        />
        <ListItemAvatar>
          <Avatar src={ticket?.contact?.profilePicUrl} />
        </ListItemAvatar>

        <ListItemText
          disableTypography
          primary={
            <span className={classes.contactNameWrapperLine1}>
              <Typography
                noWrap
                component="span"
                variant="body2"
                color="textPrimary"
              >
                {ticket.contact.name}
              </Typography>
              <div style={{ display: "flex", justifySelf: "flex-end" }}>
                <div
                  style={{
                    display: "flex",
                    justifySelf: "center",
                  }}
                >
                  {ticket.status === "pending" && (
                    <IconButton
                      className={classes.iconOptionsLine1}
                      color="primary"
                      size="small"
                      onClick={(e) =>
                        handleAcepptTicket(ticket.id, ticket.startedAt)
                      }
                      title={i18n.t("ticketsListItem.icons.newTalkTitle")}
                    >
                      <DoneIcon fontSize="inherit" />
                    </IconButton>
                  )}

                  {ticket.status === "pending" && (
                    <IconButton
                      className={classes.iconOptionsLine1}
                      color="primary"
                      size="small"
                      onClick={(e) =>
                        handleClosedTicket(ticket.id, ticket.startedAt)
                      }
                      title={i18n.t("ticketsListItem.icons.closeTalkTitle")}
                    >
                      <ClearOutlinedIcon fontSize="inherit" />
                    </IconButton>
                  )}

                  {ticket.status === "open" && (
                    <IconButton
                      className={classes.iconOptionsLine1}
                      color="primary"
                      size="small"
                      onClick={(e) =>
                        handleClosedTicket(ticket.id, ticket.startedAt)
                      }
                      title={i18n.t("ticketsListItem.icons.closeTalkTitle")}
                    >
                      <ClearOutlinedIcon fontSize="inherit" />
                    </IconButton>
                  )}

                  {ticket.status === "closed" && (
                    <IconButton
                      className={classes.iconOptionsLine1}
                      color="primary"
                      size="small"
                      title={"Fechado"}
                    >
                      <LockIcon fontSize="inherit" />
                    </IconButton>
                  )}

                  {ticket.status === "open" && (
                    <IconButton
                      className={classes.iconOptionsLine1}
                      color="primary"
                      size="small"
                      onClick={(e) =>
                        handleUpdateTicketStatus(e, "pending", null)
                      }
                      title={i18n.t("messagesList.header.buttons.return")}
                    >
                      <ReplayIcon fontSize="inherit" />
                    </IconButton>
                  )}
                </div>
                <div style={{ marginLeft: "10px", paddingTop: "1px" }}>
                  {ticket.lastMessage && (
                    <Typography
                      className={classes.lastMessageTime}
                      component="span"
                      variant="body2"
                      color="textSecondary"
                    >
                      {isSameDay(parseISO(ticket.updatedAt), new Date()) ? (
                        <>{format(parseISO(ticket.updatedAt), "HH:mm")}</>
                      ) : (
                        <>{format(parseISO(ticket.updatedAt), "dd/MM/yyyy")}</>
                      )}
                    </Typography>
                  )}
                </div>
              </div>
            </span>
          }
          secondary={
            <ListItemText>
              <span className={classes.contactNameWrapperLine2}>
                <Typography
                  className={classes.contactLastMessage}
                  noWrap
                  component="span"
                  variant="body2"
                  color="textSecondary"
                >
                  {ticket.lastMessage ? (
                    <MarkdownWrapper>{ticket.lastMessage}</MarkdownWrapper>
                  ) : (
                    <br />
                  )}
                </Typography>

                <div
                  style={{
                    display: "flex",
                    flexWrap: "nowrap",
                  }}
                >
                  {ticket.whatsappId && (
                    <div title={ticket.whatsapp?.name}>
                      <Badge
                        className={classes.iconOptionsLine2}
                        overlap="rectangular"
                        color="primary"
                      >
                        <SyncAltIcon />
                      </Badge>
                    </div>
                  )}
                </div>
              </span>
              <span className={classes.contactNameWrapperLine3}>
                {ticket.whatsappId && (
                  <div title="Conexão" className={classes.userConnection}>
                    <div className={classes.connection}>
                      {ticket.whatsapp?.name}
                    </div>
                  </div>
                )}
                {ticket.tags && (
                  <TicketListItensTagsContainer ticket={ticket?.tags} />
                )}
              </span>
            </ListItemText>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
    </React.Fragment>
  );
};

export default TicketListItem;
