import React, { useState, useEffect } from "react";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  LabelList,
  Label,
} from "recharts";
import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import toastError from "../../errors/toastError";
import formataNumero from "../../functions/formataNumero";
import {
  FormControlLabel,
  FormControl,
  InputLabel,
  Checkbox,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  customFixedHeightPaper: {
    padding: "5px 10px 20px 15px",
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
    height: 60,
    marginBottom: 5,
  },

  GridPainel: {
    paddingTop: "1px",
    overflowY: "auto",
    width: "100%",
  },

  mainPaperReport: {
    flex: 1,
    marginRight: 16,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
}));

const UserStatistic = () => {
  const [onlyToday, setOnlyToday] = useState(false);
  const classes = useStyles();
  const COLORS = [
    "#0088FE",
    "#00C49F",
    "#D17A05",
    "#909090 ",
    "#8333D8 ",
    "#FF0000",
    "#AFBB28",
    "#2980B9",
    "#FEE042",
  ];

  // const radian = Math.PI / 180;
  // const renderCustomizedLabel = ({
  //   cx,
  //   cy,
  //   midAngle,
  //   innerRadius,
  //   outerRadius,
  //   userName,
  //   percent,
  // }) => {
  //   const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  //   const x = cx + radius * Math.cos(-midAngle * radian);
  //   const y = cy + radius * Math.sin(-midAngle * radian);

  //   return (
  //     <text
  //       x={x}
  //       y={y}
  //       fill="black"
  //       textAnchor={x > cx ? "start" : "end"}
  //       dominantBaseline="central"
  //     >
  //       {`${userName}  (${(percent * 100).toFixed(2)}%)`}
  //     </text>
  //   );
  // };

  const [loading, setLoading] = useState(false);
  const [quickAnswersAll, setQuickAnswersAll] = useState([]);
  const [quickAnswers, setQuickAnswers] = useState([]);
  const [quickAnswersGrafic, setQuickAnswersGrafic] = useState([]);

  const handleChangeOnlyToday = () => {
    setOnlyToday(!onlyToday);
  };

  useEffect(() => {
    setLoading(true);
    const fetchQuickAnswers = async () => {
      try {
        const { data } = await api.get(`/UserStatistic/${onlyToday}`);
        setQuickAnswers(data.userStatistic);
        setLoading(false);
      } catch (err) {
        toastError(err);
      }
    };

    const fetchQuickAnswersGrafic = async () => {
      try {
        const { data } = await api.get(`/UserStatisticGrafic/${onlyToday}`);
        setQuickAnswersGrafic(data.userStatistic);
        setLoading(false);
      } catch (err) {
        toastError(err);
      }
    };

    fetchQuickAnswers();
    fetchQuickAnswersGrafic();
  }, [onlyToday]);

  useEffect(() => {
    setLoading(true);
    const fetchQuickAnswersGeral = async () => {
      try {
        const { data } = await api.get(`/UserStatisticGeral/${onlyToday}`);
        setQuickAnswersAll(data.userStatistic[0]);
        setLoading(false);
      } catch (err) {
        toastError(err);
      }
    };

    fetchQuickAnswersGeral();
  }, []);

  return (
    <MainContainer>
      <MainHeader>
        <Title>
          {i18n.t("mainDrawer.listItems.relatorioPorUsuario")} (estatística)
        </Title>
        <FormControlLabel
          style={{
            marginTop: "-10px",
            position: "absolute",
            right: "20px",
          }}
          control={
            <Checkbox
              size="medium"
              color="primary"
              onChange={handleChangeOnlyToday}
            />
          }
          label="Apenas o dia de hoje"
        />
        <FormControl
          style={{
            marginTop: "-40px",
            position: "absolute",
            width: "160px",
            right: "22px",
            backgroundColor: "red",
          }}
        >
          <InputLabel
            style={{
              fontSize: "12px",
            }}
          >
            (Padrão últimos 3 meses)
          </InputLabel>
        </FormControl>
      </MainHeader>

      <Grid className={classes.GridPainel} container spacing={2}>
        <Grid item xs={3}>
          <Paper
            className={classes.customFixedHeightPaper}
            style={{ overflow: "hidden" }}
          >
            <Typography component="h3" variant="" color="primary">
              {"Tickets (fechados) Geral"}
            </Typography>
            <Grid item>
              <Typography>
                {formataNumero(quickAnswersAll.userTickets)}
              </Typography>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper
            className={classes.customFixedHeightPaper}
            style={{ overflow: "hidden" }}
          >
            <Typography component="h3" variant="" color="primary">
              {"T.M. Geral"}
            </Typography>
            <Grid item>
              <Typography>{quickAnswersAll.userTM_GERAL}</Typography>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper
            className={classes.customFixedHeightPaper}
            style={{ overflow: "hidden" }}
          >
            <Typography component="h3" variant="" color="primary">
              {"T.M. Atendimento Geral"}
            </Typography>
            <Grid item>
              <Typography>{quickAnswersAll.useTM_ATENDIMENTO}</Typography>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={3}>
          <Paper
            className={classes.customFixedHeightPaper}
            style={{ overflow: "hidden" }}
          >
            <Typography component="h3" variant="" color="primary">
              {"T.M. Espera Geral"}
            </Typography>
            <Grid item>
              <Typography>{quickAnswersAll.userTM_ESPERA}</Typography>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <Paper className={classes.mainPaperReport} variant="outlined">
        <Table size="small" padding="none">
          <TableHead>
            <TableRow>
              <TableCell align="center">Usuário</TableCell>
              <TableCell align="center">Tickets (fechados)</TableCell>
              <TableCell align="center">Tickets %</TableCell>
              <TableCell align="center">T.M. Total</TableCell>
              <TableCell align="center">T.M. Atendimento</TableCell>
              <TableCell align="center">T.M. Espera</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading ? (
              <>
                {quickAnswers.map((quickAnswer) => (
                  <TableRow key={quickAnswer.userId}>
                    <TableCell align="center">{quickAnswer.userName}</TableCell>
                    <TableCell align="center">
                      {formataNumero(quickAnswer.userTickets)}
                    </TableCell>
                    <TableCell align="center">
                      {quickAnswer.userPercetTickets}
                    </TableCell>
                    <TableCell align="center">
                      {quickAnswer.userTM_GERAL}
                    </TableCell>
                    <TableCell align="center">
                      {quickAnswer.useTM_ATENDIMENTO}
                    </TableCell>
                    <TableCell align="center">
                      {quickAnswer.userTM_ESPERA}
                    </TableCell>
                  </TableRow>
                ))}
                {loading && <TableRowSkeleton columns={3} />}
              </>
            ) : (
              <></>
            )}
          </TableBody>
        </Table>
      </Paper>

      <div
        style={{
          marginTop: "4px",
          marginBottom: "10px",
          marginRight: "17px",
          marginLeft: "2px",
          color: "#4B4B4B",
          backgroundColor: "#FFF",
        }}
      >
        <span style={{ color: "#000", fontWeight: "500" }}> Legenda:</span>
          T.M.: Tempo Médio
      </div>

      <ResponsiveContainer width="100%" height="40%">
        <BarChart
          data={quickAnswersGrafic}
          layout="vertical"
          barCategoryGap="10%"
          margin={{ top: 10, right: 20, left: 0, bottom: 5 }}
          backgroundColor
        >
          <XAxis type="number"></XAxis>

          <YAxis type="category" width={150} dataKey="userName" />

          <Bar dataKey="userTickets" fill="#474747" radius={2}>
            <LabelList
              dataKey="userPercetTickets"
              fill="#474747"
              length="50px"
              position="right"
            />
            {COLORS.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Bar>
        </BarChart>

        {/* <PieChart width={400} height={400}>
            <Pie
              data={quickAnswers}
              dataKey="userTickets"
              cx="50%"
              cy="50%"
              labelLine={false}
              label={renderCustomizedLabel}
              outerRadius={97}
            >
              {quickAnswers.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
          </PieChart> */}
      </ResponsiveContainer>
    </MainContainer>
  );
};

export default UserStatistic;
