import React, { useState, useEffect, useRef } from "react";

import * as Yup from "yup";
import { Formik, FieldArray, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import CircularProgress from "@material-ui/core/CircularProgress";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";

import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { Chip } from "@material-ui/core";

// const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
// const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },

  textField: {
    marginRight: theme.spacing(1),
    flex: 1,
  },

  extraAttr: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },

  checkboxTypeDiv: {
    fontSize: 16,
  },

  checkboxType: {
    transform: "scale(1.2)",
    marginTop: 10,
    marginLeft: 2,
  },
}));

const ContactSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Mínimo 2 posições!")
    .max(50, "Máximo 50 posiçoes!")
    .required("Obrigatório"),
  number: Yup.string()
    .min(8, "Mínimo 8 posições!")
    .max(50, "Máximo 50 posiçoes!"),
  email: Yup.string().email("Email inválido"),
  notSendCampaign: Yup.boolean(),
});

const ContactModal = ({ open, onClose, contactId, initialValues, onSave }) => {
  const classes = useStyles();
  const isMounted = useRef(true);

  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  const initialState = {
    name: "",
    number: "",
    email: "",
    notSendCampaign: false,
    contactTags: selectedTags,
  };

  const [contact, setContact] = useState(initialState);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const fetchTags = async () => {
    try {
      const response = await api.get("/tags/isContact");
      const fetchedTags = response.data.tags;
      setTags(fetchedTags);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchTags();
  }, []);

  useEffect(() => {
    const fetchContact = async () => {
      if (initialValues) {
        setContact((prevState) => {
          return { ...prevState, ...initialValues };
        });
      }

      if (!contactId) return;

      try {
        const { data } = await api.get(`/contacts/${contactId}`);
        if (isMounted.current) {
          setContact(data);
          // if (Array.isArray(data.tags)) {
          setSelectedTags(data.tags);
          // }
        }
      } catch (err) {
        toastError(err);
      }
    };
    fetchContact();
  }, [contactId, open, initialValues]);

  const handleClose = () => {
    onClose();
    setContact(initialState);
    setSelectedTags([]);
  };

  const syncContactTag = async (data) => {
    try {
      const { data: responseData } = await api.post(`/tags/syncContact`, data);
      return responseData;
    } catch (err) {
      toastError(err);
    }
  };

  const handleSaveContact = async (values) => {
    try {
      if (contactId) {
        await api.put(`/contacts/${contactId}`, values);
        syncContactTag({ contactId: contactId, contactTags: selectedTags });
        handleClose();
      } else {
        const { data } = await api.post("/contacts", values);
        syncContactTag({ contactId: data.id, contactTags: selectedTags });
        if (onSave) {
          onSave(data);
        }
        handleClose();
      }
      toast.success(i18n.t("contactModal.success"));
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={handleClose} maxWidth="lg" scroll="paper">
        <DialogTitle id="form-dialog-title">
          {contactId
            ? `${i18n.t("contactModal.title.edit")}`
            : `${i18n.t("contactModal.title.add")}`}
        </DialogTitle>
        <Formik
          initialValues={contact}
          enableReinitialize={true}
          validationSchema={ContactSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveContact(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ values, errors, touched, isSubmitting }) => (
            <Form>
              <DialogContent dividers>
                <Typography variant="subtitle1" gutterBottom>
                  {i18n.t("contactModal.form.mainInfo")}
                </Typography>
                <Field
                  as={TextField}
                  label={i18n.t("contactModal.form.name")}
                  name="name"
                  autoFocus
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                  variant="outlined"
                  margin="dense"
                  className={classes.textField}
                />
                <Field
                  as={TextField}
                  label={i18n.t("contactModal.form.number")}
                  name="number"
                  error={touched.number && Boolean(errors.number)}
                  helperText={touched.number && errors.number}
                  placeholder="5513912344321"
                  variant="outlined"
                  margin="dense"
                />
                <div>
                  <Field
                    as={TextField}
                    label={i18n.t("contactModal.form.email")}
                    name="email"
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                    placeholder="Email address"
                    fullWidth
                    margin="dense"
                    variant="outlined"
                  />
                </div>
                <div className={classes.checkboxTypeDiv}>
                  <Field
                    type="checkbox"
                    name="notSendCampaign"
                    className={classes.checkboxType}
                  />
                  Não enviar campanhas para esse contato
                </div>

                <div className={classes.checkboxTypeDiv}>
                  <Autocomplete
                    multiple
                    id="tags-contact"
                    options={tags}
                    value={selectedTags}
                    onChange={(event, newValue) => {
                      setSelectedTags(newValue);
                    }}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) => {
                      return value.id === option.id;
                    }}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          style={{
                            backgroundColor: option.color || "#eee",
                            textShadow:
                              "#444444 2px -2px 5px, #444444 -2px 2px 5px, #444444 2px 2px 5px, #444444 -2px -2px 5px",
                            color: "white",
                          }}
                          label={option.name}
                          {...getTagProps({ index })}
                          size="small"
                        />
                      ))
                    }
                    style={{ width: 500 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Marcadores"
                        placeholder="Marcadores"
                      />
                    )}
                  />
                </div>

                <Typography
                  style={{ marginBottom: 5, marginTop: 20 }}
                  variant="subtitle1"
                >
                  {i18n.t("contactModal.form.extraInfo")}
                </Typography>

                <FieldArray name="extraInfo">
                  {({ push, remove }) => (
                    <>
                      {values.extraInfo &&
                        values.extraInfo.length > 0 &&
                        values.extraInfo.map((info, index) => (
                          <div
                            className={classes.extraAttr}
                            key={`${index}-info`}
                          >
                            <Field
                              as={TextField}
                              label={i18n.t("contactModal.form.extraName")}
                              name={`extraInfo[${index}].name`}
                              variant="outlined"
                              margin="dense"
                              className={classes.textField}
                            />
                            <Field
                              as={TextField}
                              label={i18n.t("contactModal.form.extraValue")}
                              name={`extraInfo[${index}].value`}
                              variant="outlined"
                              margin="dense"
                              className={classes.textField}
                            />
                            <IconButton
                              size="small"
                              onClick={() => remove(index)}
                            >
                              <DeleteOutlineIcon />
                            </IconButton>
                          </div>
                        ))}
                      <div className={classes.extraAttr}>
                        <Button
                          style={{ flex: 1 }}
                          variant="outlined"
                          color="primary"
                          onClick={() => push({ name: "", value: "" })}
                        >
                          {`+ ${i18n.t("contactModal.buttons.addExtraInfo")}`}
                        </Button>
                      </div>
                    </>
                  )}
                </FieldArray>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("contactModal.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {contactId
                    ? `${i18n.t("contactModal.buttons.okEdit")}`
                    : `${i18n.t("contactModal.buttons.okAdd")}`}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default ContactModal;
