import React, { useEffect } from "react";
import toastError from "../../errors/toastError";

import { Button, Divider, Typography } from "@material-ui/core";

const LocationPreview = ({ image, link, description }) => {
  useEffect(() => {}, [image, link, description]);

  const handleLocation = async () => {
    try {
      window.open(link);
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <>
      <div
        style={{
          minWidth: "210px",
        }}
      >
        <div>
          <iframe
            src="https://maps.google.com/maps/embed?pb=-8.035066%2C-34.9162702&z=17&hl=pt-BR|-8.035066, -34.9162702"
            width="210"
            height="210"
          />
          <div>
            <Divider />
            <Button
              fullWidth
              color="primary"
              style={{ marginBottom: "-12px" }}
              onClick={handleLocation}
              disabled={!link}
            >
              Visualizar
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default LocationPreview;
