import React, { useState, useEffect, useReducer, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import api from "../../services/api";
import { AuthContext } from "../../context/Auth/AuthContext";
import Board from "react-trello";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import openSocket from "../../services/socket-io";
import "./index.css";
import AccessTimeIcon from "@material-ui/icons/AccessTime";

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: "center",
    padding: theme.spacing(1),
  },

  button: {
    background: "#8CAE94 ",
    border: "none",
    padding: "1px 12px 1px 12px",
    color: "white",
    borderRadius: "5px",
  },
}));

const reducer = (state, action) => {
  if (action.type === "UPDATE_TAG") {
    const newListKanban = action.payload;
    const indiceTicketKanban = state.findIndex(
      (ticket) => ticket.id === newListKanban.id
    );
    if (indiceTicketKanban !== -1) {
      state[indiceTicketKanban].tags = [...newListKanban.tags];
    } else {
      state = [...newListKanban];
    }

    return [...state];
  }

  if (action.type === "DELETE_TICKET") {
    const tickectId = action.payload;
    const indiceTicketKanban = state.findIndex(
      (ticket) => ticket.id === tickectId
    );
    if (indiceTicketKanban !== -1) {
      state = [...state.filter((ticket) => ticket.id !== tickectId)];
    }

    return [...state];
  }

  if (action.type === "LOAD") {
    state = action.payload;
    return [...state];
  }
};

const Kanban = () => {
  const classes = useStyles();
  const history = useHistory();
  const [tickets, dispatch] = useReducer(reducer, []);

  const [tags, setTags] = useState([]);
  const { user } = useContext(AuthContext);

  const fetchTags = async () => {
    try {
      const response = await api.get("/tags/kanban");
      const fetchedTags = response.data.tags;
      setTags(fetchedTags);
      await fetchTickets(jsonString);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const delayDebounceFn = setInterval(() => {
      fetchTags();
    }, 90000);
    return () => clearTimeout(delayDebounceFn);
  }, []);

  useEffect(() => {
    fetchTags();
  }, []);

  useEffect(() => {
    const socket = openSocket();

    socket.on("tag", (data) => {
      if (data.action === "syncTags") {
        dispatch({
          type: "UPDATE_TAG",
          payload: data.ticket,
        });
      }
    });

    socket.on("ticket_kanban", (data) => {
      if (data.action === "closed_ticket") {
        dispatch({
          type: "DELETE_TICKET",
          payload: data.ticketId,
        });
      }
    });
    return () => {
      socket.disconnect();
    };
  }, [user]);

  const [file, setFile] = useState({
    lanes: [],
  });

  const jsonString = user.queues.map((queue) => queue.UserQueue.queueId);

  const fetchTickets = async (jsonString) => {
    try {
      let tipo = user.profile === "admin" ? "" : user.id;
      const { data } = await api.get("/tickets/kanban", {
        params: {
          queueIds: JSON.stringify(jsonString),
          status: ["open", "pending","closed"],
          userSpecific: tipo,
        },
      });
      dispatch({ type: "LOAD", payload: data.tickets });
    } catch (err) {
      console.log(err);
    }
  };

  const tamanhoMaximo = (nome) => {
    function isUpperCase(character) {
      return character !== " " && character === character.toUpperCase();
    }

    let cont = 0;
    for (var char of nome) {
      if (isUpperCase(char)) {
        cont = cont + 1;
      }
    }

    if (cont < Math.floor(nome.length * 0.2)) {
      return 27;
    } else if (cont < Math.floor(nome.length * 0.4)) {
      return 26;
    } else if (cont < Math.floor(nome.length * 0.7)) {
      return 25;
    } else {
      return 24;
    }
  };

  const tempoEspera = (data) => {
    const dataAtual = new Date();
    const dataReferencia = new Date(data);
    const minutos = Math.floor((dataAtual - dataReferencia) / 60000); // Em minutos
    let espera = "";

    if (minutos >= 1440) {
      espera += Math.floor(minutos / (60 * 24))
        .toString()
        .concat(" dia(s)");
    } else {
      const horas = Math.floor(minutos / 60);
      const minuto = minutos - horas * 60;
      espera = (horas > 0 ? horas.toString().concat("h ") : "").concat(
        (minutos > 0 ? minuto.toString() : "1").concat("m")
      );
    }

    return espera;
  };

  const tempoEsperaCor = (
    data,
    alertatVerde,
    alertatAmmarelo,
    alertaVermelho
  ) => {
    const dataAtual = new Date();
    const dataReferencia = new Date(data);
    const minutos = Math.floor((dataAtual - dataReferencia) / 60000); // Em minutos
    let espera = "#";

    if (
      alertaVermelho !== undefined &&
      alertaVermelho > 0 &&
      minutos >= alertaVermelho
    ) {
      espera =
        "red 3px -3px 5px, red -3px 3px 5px, red 3px 3px 5px, red -3px -3px 5px"; // #ff542e
    } else if (
      alertatAmmarelo !== undefined &&
      alertatAmmarelo > 0 &&
      minutos >= alertatAmmarelo
    ) {
      espera =
        "#FFE51A 3px -3px 5px, #FFE51A -3px 3px 5px, #FFE51A 3px 3px 5px, #FFE51A -3px -3px 5px"; // #FFE51A
    } else if (
      alertatVerde !== undefined &&
      alertatVerde > 0 &&
      minutos >= alertatVerde
    ) {
      espera =
        "green 3px -3px 5px, green -3px 3px 5px, green 3px 3px 5px, green -3px -3px 5px"; // #11c700
    }

    return espera;
  };

  const popularCards = () => {
    const lanes = [
      ...tags.map((tag) => {
        const filteredTickets = tickets.filter((ticket) => {
          const tagIds = ticket.tags.map((tag) => tag.id);
          return tagIds.includes(tag.id);
        });

        return {
          id: tag.id.toString(),
          title: (
            <span className="tituloTag">
              {tag.name} ( {filteredTickets.length} )
            </span>
          ),
          cards: filteredTickets.map((ticket) => ({
            id: ticket.id.toString(),
            title: ticket.contact.name
              .substr(0, tamanhoMaximo(ticket.contact.name))
              .concat(
                ticket.contact.name.length > tamanhoMaximo(ticket.contact.name)
                  ? "..."
                  : ""
              ),
            label: "(" + ticket.id.toString() + ")",
            description: (
              <div>
                {ticket.lastMessage && ticket.lastMessage.length > 1 ? (
                  <p>
                    {ticket.lastMessage
                      .substr(0, 70)
                      .concat(ticket.lastMessage.length > 70 ? "..." : "")}
                  </p>
                ) : (
                  ""
                )}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <button
                    className={classes.button}
                    onClick={() => handleCardClick(ticket.id.toString())}
                  >
                    Ver Conversa
                  </button>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "1px",
                      justifyContent: "right",
                      height: "25px",
                      minWidth: "60px",
                    }}
                  >
                    <text
                      style={{
                        textAlign: "right",
                        marginRight: "7px",
                        marginTop: "2px",
                        fontSize: "11px",
                        color: "#999",
                      }}
                    >
                      {tempoEspera(ticket.tags[0].TicketTag.createdAt)}
                    </text>
                    <AccessTimeIcon
                      style={{
                        borderRadius: "50%",
                        height: "22px",
                        width: "22px",
                        boxShadow: tempoEsperaCor(
                          ticket.tags[0].TicketTag.createdAt,
                          ticket.tags[0].kanbantempoverde,
                          ticket.tags[0].kanbantempoamarelo,
                          ticket.tags[0].kanbantempovermelho
                        ),
                      }}
                    />
                  </div>
                </div>
              </div>
            ),
            draggable: true,
            href: "/tickets/" + ticket.id.toString(),
          })),
          style: { backgroundColor: tag.color },
        };
      }),
    ];

    setFile({ lanes });
  };

  const handleCardClick = (tickedtID) => {
    history.push("/tickets/" + tickedtID);
  };

  useEffect(() => {
    popularCards();
  }, [tags, tickets]);

  const handleCardMove = async (cardId, sourceLaneId, targetLaneId) => {
    try {
      await api.delete(`/ticket-tags/${targetLaneId}`);
      await api.put(`/ticket-tags/${targetLaneId}/${sourceLaneId}`);
    } catch (err) {
      toast.success("Falha ao mover ticket entre marcadores!");
    }
  };

  const handleCardDelete = async (cardId, sourceLaneId, targetLaneIdd) => {
    try {
      await api.delete(`/ticket-tags/${cardId}`);
    } catch (err) {
      toast.success("Falha ao excluir marcador do ticket!");
    }
  };

  return (
    <div className={classes.container}>
      <Board
        data={file}
        collapsibleLanes="true"
        onCardMoveAcrossLanes={handleCardMove}
        onCardDelete={handleCardDelete}
        style={{
          display: "flex",
          backgroundColor: "#FBFBFB",
          marginRight: "3px",
          height: "calc(100vh - 67px)",
        }}
      />
    </div>
  );
};

export default Kanban;
