import { useState, useEffect } from "react";
import toastError from "../../errors/toastError";
import api from "../../services/api";

const useStatistic = ({ status, userSpecific, queueIds }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const fetchTickets = async () => {
        try {
          const { data } = await api.get("/tickets/statistic", {
            params: {
              status,
              userSpecific,
              queueIds,
            },
          });
          setCount(data.count);
        } catch (err) {
          toastError(err);
        }
      };

      fetchTickets();
    }, 500);
  }, [status, userSpecific, queueIds]);

  return { count };
};

export default useStatistic;
