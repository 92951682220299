import React, { useState, useEffect, useContext } from "react";
import openSocket from "socket.io-client";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Select from "@material-ui/core/Select";
import { toast } from "react-toastify";
import Tooltip from "@material-ui/core/Tooltip";
import api from "../../services/api";
import { i18n } from "../../translate/i18n.js";
import toastError from "../../errors/toastError";
import { SettingsContext } from '../../context/Settings/SettingsContext.js';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(3, 0, 3),
  },

  paper: {
    padding: theme.spacing(1.5),
    display: "flex",
    alignItems: "center",
    marginBottom: 10,
  },
  paper1: {
    padding: theme.spacing(1.5),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 10,
  },

  settingOption: {
    marginLeft: "auto",
    minWidth: 180,
  },

  margin: {
    margin: theme.spacing(1),
  },
}));

const Settings = () => {
  const classes = useStyles();
  const [openSelect, setOpenSelect] = useState(false);
  const [settingsSelect, setSettingsSelect] = useState("0");
  const { settings } = useContext(SettingsContext);
  const [setting, setSetting] = useState([]);

  useEffect(() => {
    if (settingsSelect !== "0") {
      setOpenSelect(true);
    } else {
      setOpenSelect(false);
    }
  }, [settingsSelect]);

  useEffect(() => {
    setSetting(settings);
    const { value } = settings.find((s) => s.key === "selectServiceTicketExpired");
    setSettingsSelect(value);
  }, [settings]);

  const handleChangeSettingSelect = async (e) => {
    setSettingsSelect(e.target.value);
    try {
      await api.put
      (`/settings/${e.target.name}`,{value: e.target.value})
        .then
        (
          (response) => {
            if (response.data.value === "0") {
              toast.success(i18n.t("settings.success"));
            }
          }
        )
        .catch
        (
          (error)=>{
            toast.error(error)
          }
        )
    } catch (err) {
      toastError(err);
    }
  };

  const handleChangeSetting = async (e) => {
    const selectedValue = e.target.value;
    const settingKey = e.target.name;

    try {
      await api.put(`/settings/${settingKey}`, {
        value: selectedValue,
      });

      toast.success(i18n.t("settings.success"));
    } catch (err) {
      toastError(err);
    }
  };

  const getSettingValue = (key) => {
    const { value } = setting.find((s) => s.key === key);
    return value;
  };

  return (
    <div className={classes.root}>
      <Container className={classes.container}>
        <Typography variant="h5" gutterBottom>
          {i18n.t("settings.title")}
        </Typography>
        <div style={{ height: 14 }}></div>
        <Paper className={classes.paper}>
          <Typography variant="body1">
            {i18n.t("settings.settings.userCreation.name")}
          </Typography>
          <Select
            margin="dense"
            variant="outlined"
            native
            id="userCreation-setting"
            name="userCreation"
            value={
              setting && setting.length > 0 && getSettingValue("userCreation")
            }
            className={classes.settingOption}
            onChange={handleChangeSetting}
          >
            <option value="enabled">
              {i18n.t("settings.settings.userCreation.options.enabled")}
            </option>
            <option value="disabled">
              {i18n.t("settings.settings.userCreation.options.disabled")}
            </option>
          </Select>
        </Paper>

        <Typography variant="body2" gutterBottom></Typography>
        <Tooltip title={i18n.t("settings.settings.timeCreateNewTicket.note")}>
          <Paper className={classes.paper} elevation={3}>
            <Typography variant="body1">
              {i18n.t("settings.settings.timeCreateNewTicket.name")}
            </Typography>
            <Select
              margin="dense"
              variant="outlined"
              native
              id="timeCreateNewTicket-setting"
              name="timeCreateNewTicket"
              value={
                setting &&
                setting.length > 0 &&
                getSettingValue("timeCreateNewTicket")
              }
              className={classes.settingOption}
              onChange={handleChangeSetting}
            >
              <option value="10">
                {i18n.t("settings.settings.timeCreateNewTicket.options.10")}
              </option>
              <option value="30">
                {i18n.t("settings.settings.timeCreateNewTicket.options.30")}
              </option>
              <option value="60">
                {i18n.t("settings.settings.timeCreateNewTicket.options.60")}
              </option>
              <option value="300">
                {i18n.t("settings.settings.timeCreateNewTicket.options.300")}
              </option>
              <option value="1800">
                {i18n.t("settings.settings.timeCreateNewTicket.options.1800")}
              </option>
              <option value="3600">
                {i18n.t("settings.settings.timeCreateNewTicket.options.3600")}
              </option>
              <option value="7200">
                {i18n.t("settings.settings.timeCreateNewTicket.options.7200")}
              </option>
              <option value="21600">
                {i18n.t("settings.settings.timeCreateNewTicket.options.21600")}
              </option>
              <option value="43200">
                {i18n.t("settings.settings.timeCreateNewTicket.options.43200")}
              </option>
              <option value="86400">
                {i18n.t("settings.settings.timeCreateNewTicket.options.86400")}
              </option>
              <option value="172800">
                {i18n.t("settings.settings.timeCreateNewTicket.options.172800")}
              </option>
            </Select>
          </Paper>
        </Tooltip>

        <Typography variant="body2" gutterBottom></Typography>
        <Paper className={classes.paper}>
          <Typography variant="body1">
            {i18n.t("settings.settings.call.name")}
          </Typography>
          <Select
            margin="dense"
            variant="outlined"
            native
            id="call-setting"
            name="call"
            value={setting && setting.length > 0 && getSettingValue("call")}
            className={classes.settingOption}
            onChange={handleChangeSetting}
          >
            <option value="enabled">
              {i18n.t("settings.settings.call.options.enabled")}
            </option>
            <option value="disabled">
              {i18n.t("settings.settings.call.options.disabled")}
            </option>
          </Select>
        </Paper>

        <Typography variant="body2" gutterBottom></Typography>
        <Paper className={classes.paper}>
          <Typography variant="body1">Assinar mensagem</Typography>
          <Select
            margin="dense"
            variant="outlined"
            native
            id="sign-setting"
            name="sign"
            value={setting && setting.length > 0 && getSettingValue("sign")}
            className={classes.settingOption}
            onChange={handleChangeSetting}
          >
            <option value="enabled">
              {i18n.t("settings.settings.call.options.enabled")}
            </option>
            <option value="disabled">
              {i18n.t("settings.settings.call.options.disabled")}
            </option>
          </Select>
        </Paper>

        <Paper className={classes.paper}>
          <Typography variant="body1">
            {i18n.t("settings.settings.CheckMsgIsGroup.name")}
          </Typography>
          <Select
            margin="dense"
            variant="outlined"
            native
            id="CheckMsgIsGroup-setting"
            name="CheckMsgIsGroup"
            value={
              setting &&
              setting.length > 0 &&
              getSettingValue("CheckMsgIsGroup")
            }
            className={classes.settingOption}
            onChange={handleChangeSetting}
          >
            <option value="enabled">
              {i18n.t("settings.settings.CheckMsgIsGroup.options.enabled")}
            </option>
            <option value="disabled">
              {i18n.t("settings.settings.CheckMsgIsGroup.options.disabled")}
            </option>
          </Select>
        </Paper>

        <Paper className={classes.paper}>
          <Typography variant="body1">Tipo do Chatbot</Typography>
          <Select
            margin="dense"
            variant="outlined"
            native
            id="chatBotType-setting"
            name="chatBotType"
            value={
              setting && setting.length > 0 && getSettingValue("chatBotType")
            }
            className={classes.settingOption}
            onChange={handleChangeSetting}
          >
            <option value="text">Texto</option>
            {/* 
            <option value="button">Botão</option>

            <option value="list">Lista</option> */}
          </Select>
        </Paper>

        <Typography variant="body2" gutterBottom></Typography>
        <Tooltip title={i18n.t("settings.settings.timeTicketExpired.note")}>
          <Paper className={classes.paper1} elevation={3}>
            <Typography variant="body1">
              {i18n.t("settings.settings.timeTicketExpired.name")}
            </Typography>
            <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
              <Select
                margin="dense"
                variant="outlined"
                native
                id="selectServiceTicketExpired-setting"
                name="selectServiceTicketExpired"
                value={settingsSelect}
                className={classes.settingOption}
                onChange={handleChangeSettingSelect}
              >
                <option value="0">
                  {i18n.t("settings.settings.timeTicketExpired.options.0")}
                </option>

                <option value="open">
                  {i18n.t("settings.settings.timeTicketExpired.options.open")}
                </option>
                <option value="pending">
                  {i18n.t(
                    "settings.settings.timeTicketExpired.options.pending"
                  )}
                </option>
                <option value="all">
                  {i18n.t("settings.settings.timeTicketExpired.options.all")}
                </option>
              </Select>
              {openSelect && (
                <Select
                  margin="dense"
                  variant="outlined"
                  native
                  id="timeTicketExpired-setting"
                  name="timeTicketExpired"
                  value={
                    setting &&
                    setting.length > 0 &&
                    getSettingValue("timeTicketExpired")
                  }
                  className={classes.settingOption}
                  onChange={handleChangeSetting}
                >
                  <option value="3600">
                    {i18n.t("settings.settings.timeTicketExpired.options.3600")}
                  </option>
                  <option value="7200">
                    {i18n.t("settings.settings.timeTicketExpired.options.7200")}
                  </option>
                  <option value="10800">
                    {i18n.t(
                      "settings.settings.timeTicketExpired.options.10800"
                    )}
                  </option>
                  <option value="21600">
                    {i18n.t(
                      "settings.settings.timeTicketExpired.options.21600"
                    )}
                  </option>
                  <option value="43200">
                    {i18n.t(
                      "settings.settings.timeTicketExpired.options.43200"
                    )}
                  </option>
                  <option value="86400">
                    {i18n.t(
                      "settings.settings.timeTicketExpired.options.86400"
                    )}
                  </option>
                  <option value="129600">
                    {i18n.t(
                      "settings.settings.timeTicketExpired.options.129600"
                    )}
                  </option>
                  <option value="172800">
                    {i18n.t(
                      "settings.settings.timeTicketExpired.options.172800"
                    )}
                  </option>
                  <option value="216000">
                    {i18n.t(
                      "settings.settings.timeTicketExpired.options.216000"
                    )}
                  </option>
                  <option value="259200">
                    {i18n.t(
                      "settings.settings.timeTicketExpired.options.259200"
                    )}
                  </option>
                </Select>
              )}
            </div>
          </Paper>
        </Tooltip>
        <Paper className={classes.paper}>
          <Typography variant="body1">Visualizar ticket sem fila</Typography>
          <Select
            margin="dense"
            variant="outlined"
            native
            id="visible-ticket-settings"
            name="visible-ticket"
            value={
              setting &&
              setting.length > 0 &&
              getSettingValue("visible-ticket")
            }
            className={classes.settingOption}
            onChange={handleChangeSetting}
          >
            <option value="enabled">
              {i18n.t("settings.settings.call.options.enabled")}
            </option>
            <option value="disabled">
              {i18n.t("settings.settings.call.options.disabled")}
            </option>
          </Select>
        </Paper>
      </Container>
    </div>
  );
};

export default Settings;
