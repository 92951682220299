import React from "react";
import { Avatar, CardHeader } from "@material-ui/core";
import { i18n } from "../../translate/i18n";
import { parseISO, format } from "date-fns";

const TicketInfo = ({ contact, ticket, onClick }) => {
  return (
    <div style={{ overflow: "hidden", height: 100 }}>
      <CardHeader
        onClick={onClick}
        style={{
          cursor: "pointer",
          overflowX: "scroll",
          height: 117,
        }}
        titleTypographyProps={{ noWrap: true }}
        subheaderTypographyProps={{ noWrap: true }}
        avatar={<Avatar src={contact.profilePicUrl} alt="contact_image" />}
        // ESPAÇO EM BRANCO INVISIVEL ALT + 255
        title={`${contact.name} #${ticket.id} (Em: ${format(
          parseISO(ticket.createdAt),
          "dd/MM/yyyy - HH:mm"
        )})`}
        subheader={
          ticket.user &&
          `${i18n.t("messagesList.header.assignedTo")} ${ticket.user.name}`
        }
      />
    </div>
  );
};

export default TicketInfo;
