import React, { useState, useRef, useCallback } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ptBR } from "date-fns/locale";
import "dayjs/locale/pt-br";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Drawer from "@material-ui/core/Drawer";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import { DataGrid } from "@mui/x-data-grid";
import { i18n } from "../../translate/i18n";
import MenuItem from "@material-ui/core/MenuItem";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { formataCpf } from "../../functions/formataCpf";
import ModalScheduleHour from "../ModalScheduleHour";
import { DateCalendar } from "@mui/x-date-pickers";
import { GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { DatePicker } from "@mui/x-date-pickers";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import axios from "axios";
import { validarCPF } from "../../functions/validadorCpf";
import { format } from "date-fns";
import { formataPhone } from "../../functions/formataPhone";
import CircularProgress from "@mui/material/CircularProgress";
import { DayCalendarSkeleton } from "@mui/x-date-pickers/DayCalendarSkeleton";
import Badge from "@mui/material/Badge";
import { toast } from "react-toastify";
import { getToken } from "../../config";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import {} from "react";
const drawerWidth = 320;
const url = "https://apiproxy.ctichat.com.br/";
const config = {
  headers: {
    Authorization: `Bearer ${getToken()}`,
  },
};

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    display: "flex",
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
  },
  header: {
    display: "flex",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    backgroundColor: "#eee",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    minHeight: "73px",
    justifyContent: "flex-start",
  },
  content: {
    display: "flex",
    backgroundColor: "#eee",
    flexDirection: "column",
    padding: "8px 0px 8px 8px",
    height: "100%",
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },

  contactAvatar: {
    margin: 15,
    width: 160,
    height: 160,
  },
  resize: {
    fontSize: 10,
  },

  contactHeader: {
    display: "flex",
    marginBottom: 10,
    padding: 8,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "& > *": {
      margin: 4,
    },
  },

  contactDetails: {
    marginTop: 8,
    padding: 8,
    display: "flex",
    flexDirection: "column",
  },
  contactExtraInfo: {
    marginTop: 4,
    padding: 6,
  },
}));

const ScheduleDrawer = ({ open, handleDrawerClose }) => {
  const classes = useStyles();
  const [cpf, setCpf] = useState("");
  const [name, setName] = useState("");
  const [paciente, setPaciente] = useState({});
  const [schedule, setSchedule] = useState([]);
  const [phone, setPhone] = useState("");
  const [dataNasc, setDataNasc] = useState(null);
  const [procedure, setProcedure] = useState("");
  const [procedures, setProcedures] = useState([]);
  const [plan, setPlan] = useState("");
  const [plans, setPlans] = useState([]);
  const [book, setBook] = useState("");
  const [books, setBooks] = useState([]);
  const [unit, setUnit] = useState("");
  const [units, setUnits] = useState([]);
  const [doctor, setDoctor] = useState("");
  const [doctors, setDoctors] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [valueObs, setValueObs] = useState("");
  const [dateValue, setDateValue] = useState("");
  const [erroCpf, setErroCpf] = useState(false);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingPaciente, setLoadingPaciente] = useState(false);
  const [hour, setHour] = useState("");
  const [hours, setHours] = useState([]);
  const [arrivalTime, setArrivalTime] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [highlightedDays, setHighlightedDays] = useState([]);
  const [loadingCalendar, setLoadingCalendar] = useState(false);
  const [monthCalendar, setMonthCalendar] = useState(new Date());
  const requestAbortController = useRef(null);

  const formataData = (data) => {
    const dia = data.getDate().toString().padStart(2, "0");
    const mesTemp = data.getMonth() + 1;
    const mes = mesTemp.toString().padStart(2, "0");

    const ano = data.getFullYear();
    const dataFormatada = `${mes}%2F${dia}%2F${ano}`;
    return dataFormatada;
  };

  function ServerDay(props) {
    const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

    const isSelected =
      !props.outsideCurrentMonth &&
      highlightedDays.indexOf(props.day.date()) >= 0;

    return (
      <Badge
        key={props.day.toString()}
        overlap="circular"
        badgeContent={
          isSelected ? (
            <CheckCircleRoundedIcon color="success" fontSize="small" />
          ) : undefined
        }
      >
        <PickersDay
          {...other}
          outsideCurrentMonth={outsideCurrentMonth}
          day={day}
        />
      </Badge>
    );
  }
  const daysHighLight = (days) => {
    setHighlightedDays(days);
    setIsLoading(false);
  };
  useState(() => {
    const loadUnits = async () => {
      await axios
        .get(`${url}GetUnidades`, config)
        .then((response) => {
          const data = response;

          setUnits(data.data.result);

          return data;
        })
        .catch((err) => {
          console.log(err);
        });
    };
    loadUnits();
  }, [units]);

  const handleMonthChange = async (date) => {
    const dateCurrent = new Date();
    setMonthCalendar(new Date(date.$d));
    const filterUnit = units.filter((item) => {
      return item.UNIDADE === unit;
    });
    const filterPlans = plans.filter((item) => {
      return item.PLANO === plan;
    });
    const filterProcedure = procedures.filter((item) => {
      return item.PROCED_DESCRICAO === procedure;
    });
    const currentMonth = date.$d;
    const initialDate = formataData(
      date.$M === dateCurrent.getMonth() ? dateCurrent : date.$d
    );
    const finalDate = formataData(
      new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0)
    );

    setIsLoading(true);
    await axios
      .get(
        `${url}GetDiasAgenda/${filterUnit[0].CODUNIDADE}/${cpf}/${initialDate}/${finalDate}/${filterProcedure[0].PROCED_CODIGO}/${filterPlans[0].CODPLANO}`,
        config
      )
      .then((response) => {
        const { data } = response;
        setIsLoading(false);
        setSchedule(data.result);

        const scheduleDays = response.data.result;
        let blackoutDates = [];

        const days = scheduleDays.filter((item) => {
          return item.MARCADO === "Sim" && item.VAGO === "Sim";
        });

        for (let i of days) {
          blackoutDates.push(Number(i.DIA));
        }
        daysHighLight(blackoutDates);

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });

    if (requestAbortController.current) {
      requestAbortController.current.abort();
    }
  };
  const handleScheduleHour = async (e) => {
    const isBadge = highlightedDays.includes(e.$D);

    if (isBadge) {
      setDateValue("");
      toast.info("Procedimento ja agendado.");
      return;
    }
    setLoading(true);
    const data = format(e.$d, "dd/MM/yyyy");

    const filterBook = books.filter((item) => {
      return item.LIVRO === book;
    });

    const formatedDate = e.$d.toLocaleDateString({
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    let dateFormat = formatedDate.replaceAll("/", ".");

    await axios
      .get(`${url}GetHorarios/${dateFormat}/${filterBook[0].CODLIVRO}`, config)
      .then((response) => {
        const { data } = response;
        let arrayHours = [];
        const hours = data.result.map((item) => {
          arrayHours.push(item.HORA);
        });
        setHours(arrayHours);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });

    setDateValue(data);
    if (arrivalTime === false) {
      setModalOpen(true);
    }
  };

  const handleDeleteClick = (e) => async () => {
    setIsLoading(true);
    const rowSelected = rows.filter((item) => {
      return item.id === e;
    });

    const agendamentoSelected = paciente.AGENDAMENTOS.filter((item, index) => {
      return rowSelected[0].id === index;
    });

    await axios
      .post(
        `${url}CancelAgendamentoWeb`,
        {
          LIVRO: agendamentoSelected[0].LIVRO,
          DATA: rowSelected[0].Data,
          HORA: rowSelected[0].Hora,
          NOMEPACIENTE: name,
          COD_PROCEDIMENTO: agendamentoSelected[0].EXAME_CODIGO,
          CONVENIO: agendamentoSelected[0].CONVENIO,
        },
        config
      )
      .then((response) => {
        const newRows = rows.filter((item) => {
          return rowSelected[0].id !== item.id;
        });

        setRows(newRows);
        // handleMonthChange(dayjs(new Date()));
        setIsLoading(false);
        toast.success(response.data.MENSAGEM);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const columns = [
    { field: "Data", headerName: "Data", maxWidth: 70 },
    {
      field: "Hora",
      headerName: "Hora",
      maxWidth: 50,
    },
    {
      field: "Procedimento",
      headerName: "Procedimento",
      flex: 1,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "#",
      cellClassName: "actions",
      width: 5,
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<DeleteIcon style={{ width: 16, height: 16 }} />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const handleUnits = async (e) => {
    setLoading(true);
    setBook("");
    setBooks([]);
    setPlan("");
    setPlans([]);
    setProcedure("");
    setProcedures([]);
    setDoctor("");
    setDoctors([]);
    setUnit(e.target.value);

    const filterUnit = units.filter((item) => {
      return item.UNIDADE === e.target.value;
    });

    await axios
      .get(`${url}GetLivrosUnidade/${filterUnit[0].CODUNIDADE}`, config)
      .then((response) => {
        const { data } = response;

        setBooks(data.result);

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleBooks = async (e) => {
    setLoading(true);
    setPlan("");
    setPlans([]);
    setProcedure("");
    setProcedures([]);
    setDoctor("");
    setDoctors([]);
    setBook(e.target.value);

    const filterBook = books.filter((item) => {
      return item.LIVRO === e.target.value;
    });

    if (filterBook[0].POR_ORDEM_CHEGADA === "Sim") {
      setArrivalTime(true);
    } else {
      setArrivalTime(false);
    }

    await axios
      .get(`${url}GetPlanosUnidade/${filterBook[0].CODLIVRO}`, config)
      .then((response) => {
        const { data } = response;
        setPlans(data.result);

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePlan = async (e) => {
    setPlan(e.target.value);
    setLoading(true);

    setProcedure("");
    setProcedures([]);
    setDoctor("");
    setDoctors([]);

    const filterPlans = plans.filter((item) => {
      return item.PLANO === e.target.value;
    });
    const filterUnit = units.filter((item) => {
      return item.UNIDADE === unit;
    });
    const filterBook = books.filter((item) => {
      return item.LIVRO === book;
    });

    await axios
      .get(
        `${url}GetProcedimentosChat/${filterUnit[0].CODUNIDADE}/${filterBook[0].CODLIVRO}/${filterPlans[0].CODPLANO}`,
        config
      )
      .then((response) => {
        const { data } = response;
        setProcedures(data.result);

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCpf = async (e) => {
    let valueCpf = e.target.value;

    setCpf(formataCpf(valueCpf));
    let newCpf = valueCpf.replace(".", "").replace(".", "").replace("-", "");

    const validaCpf = validarCPF(newCpf);

    if (validaCpf) {
      setLoadingPaciente(true);
      await axios
        .get(`${url}GetPacienteProximosAgendamentos/${newCpf}`, config)
        .then((response) => {
          const { data } = response;

          setErroCpf(false);
          setPaciente(data);

          // if (data.PRONTUARIO === "") {
          //   setName("");
          //   setDataNasc(null);
          //   setLoadingPaciente(false);
          //   setPhone("");
          //   return;
          // }

          setName(data.NOMEPACIENTE ? data.NOMEPACIENTE : "");
          setPhone(formataPhone(data.CELULAR));
          if (data.DATANASCIMENTO) {
            const dataNascimento = data.DATANASCIMENTO.split("/");
            const convertDate =
              dataNascimento[1] +
              "-" +
              dataNascimento[0] +
              "-" +
              dataNascimento[2];

            const formatData = format(new Date(convertDate), "MM-dd-yyyy");
            setDataNasc(new Date(formatData));
          } else {
            setDataNasc(null);
          }

          const newRows = data.AGENDAMENTOS.map((item, index) => {
            let data = {
              id: index,
              Data: item.DATA,
              Hora: item.HORA,
              Procedimento: item.EXAME,
            };
            return data;
          });
          setRows(newRows);
          setLoadingPaciente(false);
        })
        .catch((err) => {
          console.log(err);
        });

      return;
    }
    if (newCpf.length === 11 && validaCpf === false) {
      setErroCpf(true);
      return;
    }
    setErroCpf(false);
  };

  const handleProcedure = async (e) => {
    setProcedure(e.target.value);
    setLoading(true);
    setDoctors([]);
    const filterPlans = plans.filter((item) => {
      return item.PLANO === plan;
    });
    const filterUnit = units.filter((item) => {
      return item.UNIDADE === unit;
    });
    const filterProcedure = procedures.filter((item) => {
      return item.PROCED_DESCRICAO === e.target.value;
    });

    const formatedDate = dataNasc.toLocaleDateString({
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });

    const date = formatedDate.substr(0, 2);
    const month = formatedDate.substr(3, 2);
    const year = formatedDate.substr(6, 4);

    const convertDate = `${year}%2F${month}%2F${date}`;

    await axios
      .get(
        `${url}GetMedicosChat/${filterUnit[0].CODUNIDADE}/${filterPlans[0].CODPLANO}/${filterProcedure[0].PROCED_CODIGO}/${convertDate}`,
        config
      )
      .then((response) => {
        const { data } = response;
        setDoctors(data.result);
        setDoctor("");

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDataNasc = (e) => {
    console.log(e);

    setDataNasc(new Date(e));
  };

  const handlePhone = (e) => {
    let phoneValue = e.target.value;
    setPhone(formataPhone(phoneValue));
  };

  const handleSubmit = async () => {
    const specialty = procedures.filter((item) => {
      return item.PROCED_DESCRICAO === procedure;
    });
    const doctorFilter = doctors.filter((item) => {
      return item.MEDICO === doctor;
    });
    const planFilter = plans.filter((item) => {
      return item.PLANO === plan;
    });
    const bookFilter = books.filter((item) => {
      return item.LIVRO === book;
    });

    const formatPhone = phone.replace(/\D/g, "");
    const DDD_CEL = formatPhone.substring(0, 2);

    const formataCpf = cpf.replace(/\D/g, "");

    await axios
      .put(
        `${url}Agendar`,
        {
          EXAME: specialty[0].PROCED_CODIGO.toString(),
          CONVENIO: planFilter[0].CODPLANO.toString(),
          MEDICO: doctorFilter[0].CODMEDICO.toString(),
          NOMEPACIENTE: name,
          CELULAR: formatPhone.substring(2),
          DDD_CEL: DDD_CEL,
          AGEND_CPF: formataCpf,
          LIVRO: bookFilter[0].CODLIVRO.toString(),
          DATA: dateValue,
          HORA: arrivalTime ? "99:99" : hour,
          OBSERVACAO: valueObs,
          CODIGOPACIENTE: paciente.PRONTUARIO,
        },
        config
      )
      .then((response) => {
        toast.success("Agendamento concluido com sucesso");
        handleDrawerClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDoctor = async (e) => {
    setDoctor(e.target.value);

    const filterUnit = units.filter((item) => {
      return item.UNIDADE === unit;
    });
    const filterPlans = plans.filter((item) => {
      return item.PLANO === plan;
    });
    const filterProcedure = procedures.filter((item) => {
      return item.PROCED_DESCRICAO === procedure;
    });
    const currentMonth = monthCalendar;
    const initialDate = formataData(monthCalendar);
    const finalDate = formataData(
      new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0)
    );

    setLoading(true);
    await axios
      .get(
        `${url}GetDiasAgenda/${filterUnit[0].CODUNIDADE}/${cpf}/${initialDate}/${finalDate}/${filterProcedure[0].PROCED_CODIGO}/${filterPlans[0].CODPLANO}`,
        config
      )
      .then((response) => {
        setSchedule(response.data.result);
        const scheduleDays = response.data.result;
        let blackoutDates = [];

        const days = scheduleDays.filter((item) => {
          return item.MARCADO === "Sim" && item.VAGO === "Sim";
        });

        for (let i of days) {
          blackoutDates.push(Number(i.DIA));
        }
        daysHighLight(blackoutDates);
        setLoadingCalendar(true);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function disableEspecificDates(date) {
    const data = date.$d;
    let blackoutDates = [];

    const days = schedule.filter((item) => {
      return item.VAGO === "Não";
    });
    for (let i of days) {
      blackoutDates.push(Number(i.DIA));
    }

    return blackoutDates.includes(data.getDate());
  }

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="right"
      open={open}
      PaperProps={{ style: { position: "absolute" } }}
      BackdropProps={{ style: { position: "absolute" } }}
      ModalProps={{
        container: document.getElementById("drawer-container"),
        style: { position: "absolute" },
      }}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      {modalOpen && (
        <ModalScheduleHour
          closeModal={() => setModalOpen(false)}
          data={hours}
          selectHour={(e) => setHour(e)}
        />
      )}

      <div className={classes.header}>
        <IconButton onClick={handleDrawerClose}>
          <CloseIcon />
        </IconButton>
        <Typography style={{ justifySelf: "center" }}>
          {i18n.t("scheduleDrawer.header")}
        </Typography>
      </div>

      <div className={classes.content}>
        <Paper square variant="outlined" className={classes.contactHeader}>
          <TextField
            error={erroCpf}
            id="outlined-basic"
            label="CPF"
            variant="outlined"
            value={cpf}
            onChange={(e) => handleCpf(e)}
            size="small"
            fullWidth
            inputProps={{ style: { fontSize: 10 }, maxLength: 14 }}
            InputLabelProps={{ style: { fontSize: 12 } }}
            helperText={erroCpf ? "CPF Invalido" : false}
          />

          <TextField
            id="outlined-basic"
            label="Celular"
            variant="outlined"
            value={phone}
            disabled={
              !!Object.values(paciente).length && paciente?.CELULAR !== ""
                ? true
                : false
            }
            onChange={(e) => handlePhone(e)}
            size="small"
            fullWidth
            InputProps={{
              endAdornment: loadingPaciente && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    position: "absolute",
                  }}
                >
                  <CircularProgress
                    style={{ width: 16, height: 16 }}
                    color="inherit"
                  />
                </div>
              ),
            }}
            inputProps={{ style: { fontSize: 10 }, maxLength: 15 }}
            InputLabelProps={{ style: { fontSize: 12 } }}
            placeholder="(xx) xxxxx-xxxx"
          />
          <TextField
            id="outlined-basic"
            label="Nome"
            variant="outlined"
            size="small"
            fullWidth
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            InputProps={{
              endAdornment: loadingPaciente && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    position: "absolute",
                  }}
                >
                  <CircularProgress
                    style={{ width: 16, height: 16 }}
                    color="inherit"
                  />
                </div>
              ),
            }}
            inputProps={{
              style: {
                fontSize: 10,
              },
              maxLength: 40,
            }}
            InputLabelProps={{ style: { fontSize: 12 } }}
            value={name}
            disabled={
              !!Object.values(paciente).length && paciente?.NOMEPACIENTE !== ""
                ? true
                : false
            }
            onChange={(e) => setName(e.target.value)}
          />
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={ptBR}
          >
            <DemoContainer
              components={["DatePicker"]}
              sx={{
                width: "100%",
                paddingTop: 1,
              }}
            >
              <DatePicker
                label="Data nascimento"
                disabled={
                  !!Object.values(paciente).length &&
                  paciente?.DATANASCIMENTO !== ""
                    ? true
                    : false
                }
                disableFuture
                value={dataNasc}
                onChange={(e) => handleDataNasc(e)}
                sx={{
                  width: "100%",
                }}
                slotProps={{
                  openPickerButton: {
                    size: "small",
                  },
                  textField: {
                    size: "small",
                    inputProps: {
                      style: { fontSize: 10, padding: 10 },
                    },
                    InputLabelProps: {
                      style: { fontSize: 12 },
                    },
                  },
                }}
              />
            </DemoContainer>
          </LocalizationProvider>
        </Paper>

        {name !== "" &&
          ((cpf.length <= 14 && erroCpf === false) || cpf !== "") &&
          phone.length === 15 &&
          dataNasc instanceof Date &&
          !isNaN(dataNasc) && (
            <>
              <Paper style={{ marginBottom: 12 }}>
                {paciente.AGENDAMENTOS.length > 0 ? (
                  <DataGrid
                    sx={{
                      maxWidth: 300,
                      width: "100%",
                      position: "relative",
                      fontSize: 10,
                      border: 0,
                      height: 180,
                    }}
                    disableColumnMenu
                    disableEval
                    disableDensitySelector
                    density="compact"
                    rows={rows}
                    columns={columns}
                    hideFooter
                    hideFooterPagination
                    checkboxSelection={false}
                  />
                ) : (
                  <div
                    style={{
                      width: "100%",
                      padding: 4,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: 40,
                    }}
                  >
                    Nenhum agendamento encontrado
                  </div>
                )}
              </Paper>
              <Paper style={{ padding: 5 }}>
                <FormControl
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  size="small"
                >
                  <InputLabel style={{ fontSize: 12 }}>
                    {i18n.t("scheduleDrawer.unit")}
                  </InputLabel>
                  <Select
                    style={{ fontSize: 12 }}
                    labelWidth={60}
                    value={unit}
                    onChange={(e) => handleUnits(e)}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {units.map((queue, index) => (
                      <MenuItem
                        key={index}
                        value={queue.UNIDADE}
                        style={{ fontSize: 12 }}
                      >
                        {queue.UNIDADE}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth margin="dense" variant="outlined">
                  <InputLabel style={{ fontSize: 12 }}>
                    {i18n.t("scheduleDrawer.book")}
                  </InputLabel>
                  <Select
                    style={{
                      fontSize: 12,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    labelWidth={40}
                    autoFocus={books.length > 0 ? true : false}
                    disabled={books.length > 0 ? false : true}
                    value={book}
                    endAdornment={
                      loading &&
                      books.length <= 0 && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "absolute",
                          }}
                        >
                          <CircularProgress
                            style={{ width: 16, height: 16 }}
                            color="inherit"
                          />
                        </div>
                      )
                    }
                    onChange={(e) => handleBooks(e)}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {books.map((queue, index) => (
                      <MenuItem
                        key={index}
                        value={queue.LIVRO}
                        style={{ fontSize: 12 }}
                      >
                        {queue.LIVRO}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth margin="dense" variant="outlined">
                  <InputLabel style={{ fontSize: 12 }}>
                    {i18n.t("scheduleDrawer.plan")}
                  </InputLabel>
                  <Select
                    style={{
                      fontSize: 12,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    labelWidth={40}
                    autoFocus={plans.length > 0 ? true : false}
                    disabled={plans.length > 0 ? false : true}
                    value={plan}
                    endAdornment={
                      loading &&
                      books.length > 0 &&
                      plans.length <= 0 && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "absolute",
                          }}
                        >
                          <CircularProgress
                            style={{ width: 16, height: 16 }}
                            color="inherit"
                          />
                        </div>
                      )
                    }
                    onChange={(e) => handlePlan(e)}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {plans.map((queue, index) => (
                      <MenuItem
                        key={index}
                        value={queue.PLANO}
                        style={{ fontSize: 12 }}
                      >
                        {queue.PLANO}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth margin="dense" variant="outlined">
                  <InputLabel style={{ fontSize: 12 }}>
                    {i18n.t("scheduleDrawer.procedure")}
                  </InputLabel>
                  <Select
                    style={{
                      fontSize: 12,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    labelWidth={80}
                    value={procedure}
                    autoFocus={procedures.length > 0 ? true : false}
                    disabled={procedures.length > 0 ? false : true}
                    endAdornment={
                      loading &&
                      plans.length > 0 &&
                      procedures.length <= 0 && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "absolute",
                          }}
                        >
                          <CircularProgress
                            style={{ width: 16, height: 16 }}
                            color="inherit"
                          />
                        </div>
                      )
                    }
                    onChange={(e) => handleProcedure(e)}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {procedures.map((queue, index) => (
                      <MenuItem
                        key={index}
                        value={queue.PROCED_DESCRICAO}
                        style={{ fontSize: 12 }}
                      >
                        {queue.PROCED_DESCRICAO}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth margin="dense" variant="outlined">
                  <InputLabel style={{ fontSize: 12 }}>
                    {i18n.t("scheduleDrawer.doctor")}
                  </InputLabel>
                  <Select
                    style={{
                      fontSize: 12,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    labelWidth={50}
                    value={doctor}
                    autoFocus={doctors.length > 0 ? true : false}
                    disabled={doctors.length > 0 ? false : true}
                    endAdornment={
                      loading &&
                      doctors.length === 0 &&
                      procedures.length > 0 && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "absolute",
                          }}
                        >
                          <CircularProgress
                            style={{ width: 16, height: 16 }}
                            color="inherit"
                          />
                        </div>
                      )
                    }
                    onChange={(e) => handleDoctor(e)}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },

                      getContentAnchorEl: null,
                    }}
                  >
                    {doctors.map((queue, index) => (
                      <MenuItem
                        key={index}
                        value={queue.MEDICO}
                        style={{ fontSize: 12 }}
                      >
                        {queue.MEDICO}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  id="-multiline-flexible"
                  label="Observação"
                  multiline
                  maxRows={3}
                  variant="outlined"
                  size="small"
                  value={valueObs}
                  onChange={(text) => setValueObs(text.target.value)}
                  fullWidth
                  inputProps={{
                    style: { fontSize: 10 },
                    maxLength: 300,
                  }}
                  InputLabelProps={{ style: { fontSize: 12 } }}
                  style={{ marginTop: 8 }}
                />
                {loading && doctor && !loadingCalendar && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      position: "relative",
                      top: -150,
                    }}
                  >
                    <CircularProgress
                      style={{ width: 36, height: 36 }}
                      color="inherit"
                    />
                  </div>
                )}
                {doctor && schedule.length > 0 && (
                  <>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale={"pt-br"}
                    >
                      <DemoContainer components={["DateCalendar"]}>
                        <DateCalendar
                          autoFocus={doctor !== "" ? true : false}
                          disableHighlightToday
                          disablePast
                          loading={isLoading}
                          onMonthChange={handleMonthChange}
                          onChange={handleScheduleHour}
                          shouldDisableDate={disableEspecificDates}
                          renderLoading={() => <DayCalendarSkeleton />}
                          sx={{
                            width: "95%",
                          }}
                          slots={{
                            day: ServerDay,
                          }}
                          slotProps={{
                            day: {
                              highlightedDays,
                            },
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                    {loading && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          position: "relative",
                          top: -150,
                        }}
                      >
                        <CircularProgress
                          style={{ width: 36, height: 36 }}
                          color="inherit"
                        />
                      </div>
                    )}

                    {arrivalTime && (
                      <p
                        style={{
                          fontSize: 12,
                          color: "#FF4B45",
                          marginLeft: 4,
                          marginTop: -20,
                        }}
                      >
                        * Esse Livro o atendimento é por ordem de chegada
                      </p>
                    )}
                  </>
                )}

                <div
                  style={{
                    display: "flex",
                    gap: 5,
                    justifyContent: "center",
                    padding: 10,
                    marginTop: 8,
                  }}
                >
                  <Button
                    disabled={dateValue !== "" ? false : true}
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    style={{ fontSize: 12 }}
                  >
                    {i18n.t("scheduleDrawer.buttons.confirm")}
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleDrawerClose}
                    style={{ fontSize: 12 }}
                  >
                    {i18n.t("scheduleDrawer.buttons.cancel")}
                  </Button>
                </div>
              </Paper>
            </>
          )}
      </div>
    </Drawer>
  );
};

export default ScheduleDrawer;
