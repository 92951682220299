import React, { useContext } from "react";
import { Container } from "@material-ui/core";
import { AuthContext } from "../../context/Auth/AuthContext";

const Logout = () => {
  const { handleLogout } = useContext(AuthContext);

  const handleClickLogout = () => {
    handleLogout();
  };

  return <Container>{handleClickLogout()}</Container>;
};

export default Logout;
