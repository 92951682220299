import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  userTag: {
    display: "flex",
    flexWrap: "wrap",
    color: "#ffffff",
    fontSize: "0.8em",
    marginTop: "7px",
  },

  tag: {
    marginRight: "6px",
    borderRadius: 8,
    padding: "0 10px",
    border: "0.1px solid #AAAAAD",
    textShadow: "1px 1px 2px #000",
  },
}));

export function TicketListItensTagsContainer(Tags) {
  const classes = useStyles();
  return (
    <>
      {Tags?.ticket.length > 0 ? (
        <div key={Tags.id} title='Marcador' className={classes.userTag}>
          {Tags.ticket.map((option) => (
            <div
            className={classes.tag}
            style={{ backgroundColor: option?.color }}
            >
              {option?.name}
            </div>
          ))}
          ;
        </div>
      ) : (
        ""
        )}
    </>
  );
}
