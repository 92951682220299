import { useState,useEffect, useReducer } from 'react';

import openSocket from "../../services/socket-io";
import api from '../../services/api';
import { toast } from 'react-toastify';
import toastError from '../../errors/toastError';

const reducer = (state, action) => {
    if(action.type === "LOAD_SETTINGS")
    {
        const settings = action.payload;
        return [...settings];
    };

    if(action.type === "UPDATE_SETTINGS")
    {
        const setting = action.payload;
        const tempSettings = [...state];
        const settingIndex = tempSettings.findIndex((s) => s.key === setting.setting.key);
        if (settingIndex !== -1){
            tempSettings[settingIndex].value = setting.setting.value;
        };
        return tempSettings;
    }
};

const useSettings = () =>{
    const [settings, dispatch] = useReducer(reducer,[]);
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        setLoading(true);
        try {
            api.get("/settings")
            .then((response)=>{
                dispatch({type:"LOAD_SETTINGS", payload: response.data});
                setLoading(false);
            })
            .catch((error)=>{
                setLoading(false);
                toastError(error);
            })
        } catch (error) {
            setLoading(false);
            toastError(error);
        }
    },[]);

    useEffect(()=>{
        const socket = openSocket();

        socket.on("settings", (data) => {
            if (data.action === "update") {
                dispatch({type: "UPDATE_SETTINGS", payload: data });
            };
          });
      
          return () => {
            socket.disconnect();
          };
    },[])


    return {settings, loading}
};

export default useSettings;