import React, { useContext, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import {
  AddCircleOutline,
  ChatBubbleOutlineOutlined,
  LibraryBooks,
} from "@material-ui/icons";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Divider from "@material-ui/core/Divider";
import { Badge } from "@material-ui/core";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import ContactPhoneOutlinedIcon from "@material-ui/icons/ContactPhoneOutlined";
import AccountTreeOutlinedIcon from "@material-ui/icons/AccountTreeOutlined";
import QuestionAnswerOutlinedIcon from "@material-ui/icons/QuestionAnswerOutlined";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import RotateRight from "@material-ui/icons/RotateRight";
import EventIcon from "@material-ui/icons/Event";
import { i18n } from "../translate/i18n";
import { WhatsAppsContext } from "../context/WhatsApp/WhatsAppsContext";
import { AuthContext } from "../context/Auth/AuthContext";
import { Can } from "../components/Can";
import { makeStyles } from "@material-ui/core/styles";
import LoyaltyRoundedIcon from "@material-ui/icons/ViewWeek";

const useStyles = makeStyles((theme) => ({
  ListSubheader: {
    height: 26,
  },
}));

function ListItemLink(props) {
  const { icon, primary, to, className } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to]
  );

  return (
    <li>
      <ListItem button dense component={renderLink} className={className}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}

const MainListItems = (props) => {
  const classes = useStyles();
  const { whatsApps } = useContext(WhatsAppsContext);
  const { user } = useContext(AuthContext);
  const [connectionWarning, setConnectionWarning] = useState(false);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (whatsApps.length > 0) {
        const offlineWhats = whatsApps.filter((whats) => {
          return (
            whats.status === "qrcode" ||
            whats.status === "PAIRING" ||
            whats.status === "DISCONNECTED" ||
            whats.status === "TIMEOUT" ||
            whats.status === "OPENING"
          );
        });
        if (offlineWhats.length > 0) {
          setConnectionWarning(true);
        } else {
          setConnectionWarning(false);
        }
      }
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [whatsApps]);

  return (
    <div>
      <ListSubheader
        hidden={!props.drawerOpen}
        color="inherit"
        style={{
          marginTop: "-15px",
          marginBottom: "-10px",
          position: "static",
        }}
      >
        Atendimento
      </ListSubheader>
      <ListItemLink
        to="/tickets"
        primary={i18n.t("mainDrawer.listItems.tickets")}
        icon={<WhatsAppIcon />}
        className={classes.ListSubheader}
      />
      <ListItemLink
        to="/contacts"
        primary={i18n.t("mainDrawer.listItems.contacts")}
        icon={<ContactPhoneOutlinedIcon />}
        className={classes.ListSubheader}
      />
      <ListItemLink
        to="/quickAnswers"
        primary={i18n.t("mainDrawer.listItems.quickAnswers")}
        icon={<QuestionAnswerOutlinedIcon />}
        className={classes.ListSubheader}
      />
      <ListItemLink
        to="/schedules"
        primary={i18n.t("mainDrawer.listItems.schedules")}
        icon={<EventIcon />}
        className={classes.ListSubheader}
      />
      <ListItemLink
        to="/tags"
        primary={i18n.t("mainDrawer.listItems.tags")}
        icon={<LocalOfferIcon />}
        className={classes.ListSubheader}
      />
      <ListItemLink
        to="/Kanban"
        primary={i18n.t("Kanban")}
        icon={<LoyaltyRoundedIcon />}
        className={classes.ListSubheader}
      />
      <Can
        role={user.profile}
        perform="drawer-admin-items:view"
        yes={() => (
          <>
            <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
            {/* <ListSubheader
              hidden={!props.drawerOpen}
              color="inherit"
              style={{
                marginTop: "-15px",
                marginBottom: "-7px",
                position: "static",
              }}
            >
              Campanhas
            </ListSubheader>

            <ListItemLink
              to="/BulkMessage"
              primary="Campanhas"
              icon={<ChatBubbleOutlineOutlined />}
              className={classes.ListSubheader}
            />
            <ListItemLink
              to="/MassMessageReport"
              primary="Relatório de Envios"
              icon={<LibraryBooks />}
              className={classes.ListSubheader}
            />
            <ListItemLink
              to="/SettingsMessage"
              primary="Configurar Campanhas"
              icon={<AddCircleOutline />}
              className={classes.ListSubheader}
            />
            <Divider style={{ marginTop: "10px", marginBottom: "10px" }} /> */}
            <ListSubheader
              hidden={!props.drawerOpen}
              color="inherit"
              style={{
                marginTop: "-15px",
                marginBottom: "-7px",
                position: "static",
              }}
            >
              Gerência
            </ListSubheader>
            <ListItemLink
              to="/"
              primary={i18n.t("mainDrawer.listItems.dashboard")}
              icon={<DashboardOutlinedIcon />}
              className={classes.ListSubheader}
            />
            <ListItemLink
              to="/UserStatistic"
              primary={i18n.t("mainDrawer.listItems.relatorioPorUsuario")}
              icon={<LibraryBooks />}
              className={classes.ListSubheader}
            />
            <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
            <ListSubheader
              hidden={!props.drawerOpen}
              color="inherit"
              style={{
                marginTop: "-15px",
                marginBottom: "-7px",
                position: "static",
              }}
            >
              {i18n.t("mainDrawer.listItems.administration")}
            </ListSubheader>
            <ListItemLink
              to="/connections"
              primary={i18n.t("mainDrawer.listItems.connections")}
              icon={
                <Badge
                  overlap="rectangular"
                  badgeContent={connectionWarning ? "!" : 0}
                  color="error"
                >
                  <SyncAltIcon />
                </Badge>
              }
              className={classes.ListSubheader}
            />
            <ListItemLink
              to="/users"
              primary={i18n.t("mainDrawer.listItems.users")}
              icon={<PeopleAltOutlinedIcon />}
              className={classes.ListSubheader}
            />
            <ListItemLink
              to="/queues"
              primary={i18n.t("mainDrawer.listItems.queues")}
              icon={<AccountTreeOutlinedIcon />}
              className={classes.ListSubheader}
            />

            <ListItemLink
              to="/settings"
              primary={i18n.t("mainDrawer.listItems.settings")}
              icon={<SettingsOutlinedIcon />}
              className={classes.ListSubheader}
            />

            <ListItemLink
              to="/tokens"
              primary={i18n.t("mainDrawer.listItems.tokens")}
              icon={<VpnKeyIcon />}
              className={classes.ListSubheader}
            />

            <ListItemLink
              to="/docs"
              primary={i18n.t("mainDrawer.listItems.docs")}
              icon={<MenuBookIcon />}
              className={classes.ListSubheader}
            />
          </>
        )}
      />

      <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
      <ListItemLink
        to="/logout"
        primary={i18n.t("mainDrawer.listItems.logout")}
        icon={<RotateRight />}
        className={classes.ListSubheader}
      />
    </div>
  );
};

export default MainListItems;
