import React, { useContext, useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import MainContainer from "../../components/MainContainer";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import useTickets from "../../hooks/useTickets";
import useStatistic from "../../hooks/useStatistic";
import { AuthContext } from "../../context/Auth/AuthContext";
import { i18n } from "../../translate/i18n";
import Chart from "./Chart";
import ChartToday from "./ChartToday";
import ChartDay from "./ChartDay";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import useUsers from "../../hooks/useUsers";
import formataNumero from "../../functions/formataNumero";

const useStyles = makeStyles((theme) => ({
  GridPainel: {
    paddingTop: "1px",
    overflowY: "auto",
    width: "100%",
  },

  customFixedHeightPaper: {
    padding: "5px 10px 20px 15px",
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: 80,
  },

  fixedHeightPaperChat: {
    padding: "7px 10px 7px 15px",
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: 200,
  },
  maxWidth: {
    position: "absolute",
    right: "32px",
    width: "300px",
    height: "45px",
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const { findAll: findAllUsers } = useUsers();

  var userQueueIds = [];

  useEffect(() => {
    const loadUsers = async () => {
      const list = await findAllUsers();
      setUsers(list.users);
    };
    loadUsers();
  }, []);

  if (user.queues && user.queues.length > 0) {
    userQueueIds = user.queues.map((q) => q.id);
  }

  const GetTickets = (status) => {
    const { count } = useStatistic({
      status: status,
      userSpecific: selectedUser,
      queueIds: JSON.stringify(userQueueIds),
    });
    return formataNumero(count);
  };

  return (
    <MainContainer>
      <MainHeader>
        <Title>{i18n.t("mainDrawer.listItems.dashboard")}</Title>
        <FormControl variant="outlined" className={classes.maxWidth}>
          <InputLabel margin="dense">
            {i18n.t("userModal.finds.find")}
          </InputLabel>
          <Select
            margin="dense"
            labelWidth={55}
            value={selectedUser}
            onChange={(e) => setSelectedUser(e.target.value)}
          >
            <MenuItem value={""}>&nbsp;</MenuItem>
            {users.map((queue) => (
              <MenuItem key={queue.id} value={queue.id}>
                {queue.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </MainHeader>
      <Grid className={classes.GridPainel} container spacing={2}>
        <Grid item xs={3}>
          <Paper
            className={classes.customFixedHeightPaper}
            style={{ overflow: "hidden" }}
          >
            <Typography component="h3" variant="inherit" color="primary">
              {i18n.t("dashboard.messages.inAttendance.title")} (usuário)
            </Typography>
            <Grid item>
              <Typography variant="h4">{GetTickets("open")}</Typography>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper
            className={classes.customFixedHeightPaper}
            style={{ overflow: "hidden" }}
          >
            <Typography component="h3" variant="inherit" color="primary">
              {i18n.t("dashboard.messages.waiting.title")} (usuário)
            </Typography>
            <Grid item>
              <Typography variant="h4">{GetTickets("pending")}</Typography>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper
            className={classes.customFixedHeightPaper}
            style={{ overflow: "hidden" }}
          >
            <Typography component="h3" variant="inherit" color="primary">
              {i18n.t("dashboard.messages.closed.title")} (usuário)
            </Typography>
            <Grid item>
              <Typography variant="h4">{GetTickets("closed")}</Typography>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={3}>
          <Paper
            className={classes.customFixedHeightPaper}
            style={{ overflow: "hidden" }}
          >
            <Typography component="h3" variant="inherit" color="primary">
              {i18n.t("dashboard.messages.all.title")} (usuário)
            </Typography>
            <Grid item>
              <Typography variant="h4">{GetTickets("")}</Typography>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper className={classes.fixedHeightPaperChat}>
            <ChartToday
              userQueueIds={userQueueIds}
              userSpecific={selectedUser}
            />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.fixedHeightPaperChat}>
            <Chart userQueueIds={userQueueIds} userSpecific={selectedUser} />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.fixedHeightPaperChat}>
            <ChartDay userQueueIds={userQueueIds} userSpecific={selectedUser} />
          </Paper>
        </Grid>
      </Grid>
      <div
        style={{
          color: "#4B4B4B",
          marginTop: 20,
        }}
      >
        Obs: Tickets acumulados / por dia da semana, últimos 3 meses
      </div>
    </MainContainer>
  );
};

export default Dashboard;
