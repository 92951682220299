import React, { useState, useEffect } from "react";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { useTheme } from "@material-ui/core/styles";
import {
  BarChart,
  CartesianGrid,
  Bar,
  XAxis,
  YAxis,
  Label,
  LabelList,
  ResponsiveContainer,
} from "recharts";
import { i18n } from "../../translate/i18n";
import formataNumero from "../../functions/formataNumero";
import Title from "./Title";

const Chart = ({ userQueueIds, userSpecific }) => {
  const theme = useTheme();
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const fetchCountByDay = async () => {
      try {
        const { data } = await api.get(
          `/UserStatisticCountByDay/${userSpecific ? userSpecific : "0"}`
        );
        setChartData(data.userStatistic);
      } catch (err) {
        toastError(err);
      }
    };

    fetchCountByDay();
  }, [userSpecific]);

  return (
    <React.Fragment>
      <Title>{`${i18n.t("dashboard.charts.perDayWeek.title")}`}</Title>
      <ResponsiveContainer>
        <BarChart
          data={chartData}
          barSize={90}
          width={730}
          height={250}
          margin={{
            top: 16,
            right: 16,
            bottom: 0,
            left: 20,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="dayName" stroke={theme.palette.text.secondary} />
          <YAxis
            type="number"
            allowDecimals={false}
            stroke={theme.palette.text.secondary}
          >
            <Label
              angle={270}
              position="left"
              style={{ textAnchor: "middle", fill: theme.palette.text.primary }}
            >
              Tickets
            </Label>
          </YAxis>
          <Bar dataKey="amount" fill={"#354AA0"}>
            <LabelList position="top" formatter={formataNumero}></LabelList>
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
};

export default Chart;
