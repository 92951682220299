import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import toastError from "../../errors/toastError";
import api from "../../services/api";

import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import { AuthContext } from "../../context/Auth/AuthContext";

import { Button, Divider } from "@material-ui/core";

const VcardPreview = ({ contact, numbers, whatsappId }) => {
  const history = useHistory();
  const { user } = useContext(AuthContext);

  const [selectedContact, setContact] = useState({
    name: "",
    number: 0,
    profilePicUrl: "",
  });

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const fetchContacts = async () => {
        try {
          let contactObj = {
            name: contact,
            number: numbers.replace(/\D/g, ""),
            email: "",
          };
          const { data } = await api.post("/contact", contactObj);
          setContact(data);
        } catch (err) {
          toastError(err);
        }
      };
      fetchContacts();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [contact, numbers]);

  const handleNewChat = async () => {
    try {
      const { data: ticket } = await api.post("/tickets", {
        contactId: selectedContact.id,
        userId: user.id,
        whatsappId,
        status: "open",
      });

      history.push(`/tickets/${ticket.id}`);
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <>
      <div
        style={{
          maxWidth: "330px",
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={2}>
            <Avatar src={selectedContact.profilePicUrl} />
          </Grid>
          <Grid item xs={10}>
            <Typography
              style={{
                marginTop: "8px",
                marginLeft: "10px",
              }}
              variant="subtitle1"
              gutterBottom
            >
              {selectedContact.name}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
            <div
              style={{
                display: "flex",
                height: "24px",
                paddingTop: "6px",
                justifyContent: "center",
              }}
            >
              <Button
                fullWidth
                color="primary"
                size="small"
                variant="contained"
                style={{
                  paddingTop: "6px",
                  width: "140px",
                  height: "20px",
                }}
                onClick={handleNewChat}
                disabled={!selectedContact.number}
              >
                Conversar
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default VcardPreview;
