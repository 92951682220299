import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import ContactModal from "../ContactModal";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";

import QueueSelect from "../QueueSelect";
import { WhatsAppsContext } from '../../context/WhatsApp/WhatsAppsContext';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

const filter = createFilterOptions({
  trim: true,
});

const ModalSelectQueue = ({ modalOpen, onClose, contact }) => {
  const history = useHistory();

  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [selectedContact, setSelectedContact] = useState(null);
  const [newContact, setNewContact] = useState({});
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const { user } = useContext(AuthContext);
  const [queue, setQueue] = useState([]);
  const [listQueue, setListQueue] = useState([]);
  const [selectedWhatsapp, setSelectedWhatsapp] = useState(null);
  const { whatsApps } = useContext(WhatsAppsContext)
  useEffect(() => {
    if (!modalOpen || searchParam.length < 3) {
      setLoading(false);
      setListQueue(user.queues);
      return;
    }
    setLoading(true);

    const delayDebounceFn = setTimeout(() => {
      const fetchContacts = async () => {
        try {
          const { data } = await api.get("contacts", {
            params: { searchParam },
          });
          setOptions(data.contacts);

          setLoading(false);
        } catch (err) {
          setLoading(false);
          toastError(err);
        }
      };

      fetchContacts();
    }, 500);
    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [searchParam, modalOpen]);

  const handleClose = () => {
    onClose();
    setQueue([]);
    setListQueue([]);
    setSearchParam("");
    setSelectedContact(null);
    setSelectedWhatsapp(null);
  };

  const handleSaveTicket = async (contactId) => {
    if (!contactId) return;
    setLoading(true);

    try {
      const { data: ticket } = await api.post("/tickets", {
        contactId: contactId,
        userId: user.id,
        status: "open",
        queueId: queue[0],
        whatsappId: selectedWhatsapp,
      });
      history.push(`/tickets/${ticket.id}`);
    } catch (err) {
      toastError(err);
    }
    setLoading(false);

    handleClose();
  };

  const handleCloseContactModal = () => {
    setContactModalOpen(false);
  };

  const handleAddNewContactTicket = (contact) => {
    handleSaveTicket(contact.id);
  };

  return (
    <>
      <ContactModal
        open={contactModalOpen}
        initialValues={newContact}
        onClose={handleCloseContactModal}
        onSave={handleAddNewContactTicket}
      ></ContactModal>
      <Dialog open={modalOpen} onClose={handleClose}>
        <DialogTitle id="form-dialog-title">
          {i18n.t("modalSelect.title")}
        </DialogTitle>
        <DialogContent dividers>
          <QueueSelect
            selectedQueueIds={queue}
            onChange={(e) => setQueue([e])}
            listQueue={listQueue}
            limite={1}
          />

          <FormControl fullWidth margin="dense" variant="outlined">
            <InputLabel>Conexão</InputLabel>
            <Select
              required
              labelId='select-whatsapp-labe'
              id='select-whatsapp'
              labelWidth={80}
              value={selectedWhatsapp}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              onChange={(e)=> setSelectedWhatsapp(e.target.value)}
            >
              {whatsApps?.map((whatsapp)=>{
                if(whatsapp.status === 'CONNECTED') {
                  return (
                    <MenuItem key={whatsapp.id} value={whatsapp.id}>
                      {whatsapp.name}
                    </MenuItem>
                  )
                }                
              })}
            </Select>
          </FormControl>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={handleClose}
            color="secondary"
            disabled={loading}
            variant="outlined"
          >
            {i18n.t("modalSelect.buttons.cancel")}
          </Button>
          <ButtonWithSpinner
            variant="contained"
            type="button"
            disabled={queue.length === 0 || selectedWhatsapp === null ? true : false}
            onClick={() => handleSaveTicket(contact)}
            color="primary"
            loading={loading}
          >
            {i18n.t("modalSelect.buttons.ok")}
          </ButtonWithSpinner>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ModalSelectQueue;
