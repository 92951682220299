import { Button } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import React, { useState } from "react";
const ModalScheduleHour = ({ closeModal, data, selectHour }) => {
  const handleHour = (e) => {
    selectHour(e);
    closeModal();
  };
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0,0,0,0.6)",
        position: "absolute",
        zIndex: 99,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          backgroundColor: "#FFF",
          width: 150,
          display: "flex",
          flexDirection: "column",
          maxHeight: 400,
          borderRadius: 5,
          padding: 8,
          overflowY: "scroll",
        }}
      >
        <div>
          <IconButton onClick={closeModal}>
            <CloseIcon />
          </IconButton>
        </div>
        <div
          style={{
            padding: 8,
            fontSize: 16,
            fontWeight: "bold",
            textAlign: "center",
            borderBottom: "solid",
            borderBottomWidth: 2,
            borderBottomColor: "#CCC",
          }}
        >
          Hora
        </div>
        {data.length === 0 && (
          <div
            style={{
              width: "100%",
              textAlign: "center",
              fontSize: 16,
              padding: 4,
            }}
          >
            Sem horários
          </div>
        )}
        {data.map((item, index) => (
          <div key={index} style={{ width: "100%", textAlign: "center" }}>
            <Button onClick={() => handleHour(item)} style={{ width: "100%" }}>
              {item}
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ModalScheduleHour;
