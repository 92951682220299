import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { AuthProvider } from "../context/Auth/AuthContext";
import { WhatsAppsProvider } from "../context/WhatsApp/WhatsAppsContext";
import LoggedInLayout from "../layout";
import Connections from "../pages/Connections/";
import Contacts from "../pages/Contacts/";
import Dashboard from "../pages/Dashboard/";
import Login from "../pages/Login/";
import Logout from "../pages/Logout/";
import Queues from "../pages/Queues/";
import QuickAnswers from "../pages/QuickAnswers/";
import Schedules from "../pages/Schedules/";
import SendMassMessage from "../pages/SendMassMessage";
import SettingMessage from "../pages/SettingMessage";
import Settings from "../pages/Settings/";
import MassMessageReport from "../pages/MassMessageReport";
// import Signup from "../pages/Signup/";
import Tags from "../pages/Tags/";
import Tickets from "../pages/Tickets/";
import Users from "../pages/Users";
import UserStatistic from "../pages/UserStatistic";
import Route from "./Route";
import Docs from "../pages/docs/";
import Swagger from "../pages/swagger/";
import Tokens from "../pages/tokens/";
import Kanban from "../pages/Kanban";
import { SettingsProvider } from '../context/Settings/SettingsContext';

const Routes = () => {
  return (
      <BrowserRouter>
        <AuthProvider>
          <Switch>
            <Route exact path="/login" component={Login} />
            <SettingsProvider>
              <WhatsAppsProvider>
                <LoggedInLayout>
                  <Route exact path="/" component={Dashboard} isPrivate />
                  <Route
                    exact
                    path="/tickets/:ticketId?"
                    component={Tickets}
                    isPrivate
                  />
                  <Route
                    exact
                    path="/connections"
                    component={Connections}
                    isPrivate
                  />
                  <Route exact path="/contacts" component={Contacts} isPrivate />
                  <Route exact path="/users" component={Users} isPrivate />
                  <Route
                    exact
                    path="/quickAnswers"
                    component={QuickAnswers}
                    isPrivate
                  />
                  <Route exact path="/logout" component={Logout} isPrivate />
                  <Route exact path="/Settings" component={Settings} isPrivate />
                  <Route exact path="/docs" component={Docs} isPrivate />
                  <Route exact path="/swagger" component={Swagger} isPrivate />
                  <Route exact path="/tokens" component={Tokens} isPrivate />
                  <Route exact path="/Queues" component={Queues} isPrivate />
                  <Route exact path="/tags" component={Tags} isPrivate />
                  <Route exact path="/schedules" component={Schedules} isPrivate />
                  <Route
                    exact
                    path="/BulkMessage"
                    component={SendMassMessage}
                    isPrivate
                  />
                  <Route
                    exact
                    path="/MassMessageReport"
                    component={MassMessageReport}
                    isPrivate
                  />
                  <Route
                    exact
                    path="/userStatistic"
                    component={UserStatistic}
                    isPrivate
                  />
                  <Route
                    exact
                    path="/SettingsMessage"
                    component={SettingMessage}
                    isPrivate
                  />
                  <Route exact path="/Kanban" component={Kanban} isPrivate />
                </LoggedInLayout>
              </WhatsAppsProvider>
            </SettingsProvider>
          </Switch>
          <ToastContainer autoClose={3000} />
        </AuthProvider>
      </BrowserRouter>
  );
};

export default Routes;
