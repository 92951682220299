import api from "../../services/api";

const useUsers = () => {
  const findAll = async () => {
    const { data } = await api.get("/users");
    return data;
  };

  return { findAll };
};

export default useUsers;
