import { Chip, Paper, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";
import { isArray, isString } from "lodash";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";

export function TagsContainer({ ticket }) {
  const [tags, setTags] = useState([]);
  const [selecteds, setSelecteds] = useState([]);

  useEffect(() => {
    if (ticket) {
      async function fetchData() {
        await loadTags();
        if (Array.isArray(ticket.tags)) {
          setSelecteds(ticket.tags);
        }
      }
      fetchData();
    }
  }, [ticket]);

  const createTag = async (data) => {
    try {
      const { data: responseData } = await api.post(`/tags`, data);
      return responseData;
    } catch (err) {
      toastError(err);
    }
  };

  const loadTags = async () => {
    try {
      const { data } = await api.get(`/tags/list`);
      setTags(data);
    } catch (err) {
      toastError(err);
    }
  };

  const syncTags = async (data) => {
    try {
      const { data: responseData } = await api.post(`/tags/sync`, data);
      return responseData;
    } catch (err) {
      toastError(err);
    }
  };

  const onChange = async (value, reason) => {
    let optionsChanged = [];
    if (reason === "create-option") {
      if (isArray(value)) {
        for (let item of value) {
          if (isString(item)) {
            const newTag = await createTag({ name: item });
            optionsChanged.push(newTag);
          } else {
            optionsChanged.push(item);
          }
        }
      }
      await loadTags();
    } else {
      optionsChanged = value;
    }
    setSelecteds(optionsChanged);
    await syncTags({ ticketId: ticket.id, tags: optionsChanged });
  };

  return (
    <Paper style={{ padding: 12 }}>
      <Autocomplete
        multiple
        size="small"
        options={tags}
        value={selecteds}
        freeSolo
        onChange={(e, v, r) => onChange(v, r)}
        disabled={ticket.status !== "open"}
        getOptionLabel={(option) => option.name}
        getOptionSelected={(option, value) => {
          return value.id === option.id;
        }}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              variant="outlined"
              style={{
                backgroundColor: option.color || "#eee",
                textShadow: "1px 1px 1px #000",
                color: "white",
              }}
              label={option.name}
              {...getTagProps({ index })}
              size="small"
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder={
              ["open", "closed"].includes(ticket.status)
                ? i18n.t("tagsChoice.placeholderOpen")
                : i18n.t("tagsChoice.placeholderClosed")
            }
          />
        )}
        PaperComponent={({ children }) => (
          <Paper style={{ width: 400, marginLeft: 12 }}>{children}</Paper>
        )}
      />
    </Paper>
  );
}
